// required modules
import Service from '@/services/api/Service'
import PEconomicActivityModel from '@/models/Provider/PEconomicActivity'

/**
 * This class describes a PEconomicActivity service.
 *
 * @class PEconomicActivity (name)
 */
export default class PEconomicActivityService extends Service {

  /**
   * Define the PEconomicActivity model.
   *
   * @return {Function} PEconomicActivity resource.
   */
  static model() {
    return PEconomicActivityModel
  }

  /**
   * 
   */
  static getAll() {
    return this
      .query()
      .limit(-1)
      .get()
  }
}