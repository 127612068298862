// required modules
import Model from '@/models/Model'

/**
 * This class describes a PEconomicActivity model.
 *
 * @class PEconomicActivity
 */
export default class PEconomicActivityModel extends Model {

  /**
   * Define the PEconomicActivity resource.
   *
   * @return {String} PEconomicActivity endpoint name.
   */
  static resource() {
    return 'economic_activity'
  }
}