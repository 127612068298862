// required modules
import Service from '@/services/api/Service'
import PCertificationModel from '@/models/Provider/PCertification'

/**
 * This class describes a PCertification service.
 *
 * @class PCertification (name)
 */
export default class PCertificationService extends Service {

  /**
   * Define the PCertification model.
   *
   * @return {Function} PCertification resource.
   */
  static model() {
    return PCertificationModel
  }

  /**
   * 
   */
  static getAll() {
    return this
      .query()
      .limit(-1)
      .get()
  }
}